// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-song-js": () => import("./../../../src/templates/song.js" /* webpackChunkName: "component---src-templates-song-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

